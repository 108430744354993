<template>
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="card p-15 h-100">
        <div class="d-flex align-items-center">
          <div>
            <img
              style="width: 150px"
              class="rounded-circle"
              src="media/avatars/blank.png"
              alt=""
            />
          </div>
          <div class="mx-5">
            <h2 class="text-gray">{{ usuarioInfo.nombre }}</h2>
            <h4 class="text-blue">Invitado</h4>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 my-5 p-5">
          <ul class="listado-info" style="list-style: none">
            <li class="my-5">
              <label class="text-blue" for="">Email</label>
              <p>{{ usuarioInfo.correo }}</p>
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Número de contacto</label>
              <div class="d-flex justify-content-between align-items-center">
                <p>{{ usuarioInfo.movil }}</p>
                <router-link
                  :to="{
                    name: 'editar-invitados',
                    params: { id: route.params.id },
                  }"
                  class="text-blue btn p-0"
                >
                  Editar
                </router-link>
              </div>
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Empresa</label>
              <div class="d-flex justify-content-between align-items-center">
                <p>Pharmasan SAS</p>
              </div>
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Grupos</label>
              <div class="d-flex justify-content-between align-items-center">
                <p>Bucaramanga</p>
                <button class="text-blue btn p-0">Editar</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="card p-10 h-100">
        <div class="border-bottom">
          <h2 class="text-blue mb-5">Ubicaciones asignadas</h2>
        </div>
        <div
          style="height: calc(100% - 50px)"
          v-for="(grupo, o) in usuarioInfo.grupos"
          :key="o"
        >
          <div class="my-4">
            <p class="fw-bold text-blue">Grupo</p>
            <h2 class="text-blue">{{ grupo.nombre }}</h2>
          </div>
          <div>
            <div
              class="border-bottom d-flex justify-content-between align-items-center"
            >
              <div class="d-flex align-items-center py-5">
                <div>
                  <i
                    class="bi bi-geo-alt-fill py-3 px-4 bg-blue text-white rounded-circle"
                    style="font-size: 27px"
                  ></i>
                </div>
                <div class="p-4 text-gray">
                  <h4 class="text-gray">Salón 1</h4>
                  <span>Áreas: 3</span>
                </div>
              </div>
              <div>
                <button class="btn">
                  <i class="bi bi-chevron-double-down"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import miGrupoService from "@/services/miGrupo.service";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ver-invitado",
  components: {},
  setup() {
    const usuarioInfo = ref({});
    const route = useRoute();
    const traerInformacionDelUsuario = () => {
      miGrupoService.verDelUsuario(route.params.id).then((data) => {
        console.log("respuesta informacion de usuario", data.data);
        usuarioInfo.value = data.data;
      });
    };
    onMounted(() => {
      traerInformacionDelUsuario(route.params.id);
    });
    return { usuarioInfo, route };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.listado-info {
  padding: 0;
  list-style: none;
}
.listado-info li label {
  font-size: 1.125rem;
}
.listado-info li p {
  font-size: 1.125rem;
  color: #999999;
}
</style>
